<script lang="ts" setup>
const props = defineProps<{
    modelValue?: number
    label?: string
    labelIcon?: string
    positiveOnly?: boolean
    currency?: boolean
    increments?: number[]
    unit?: string
    allowDecimals?: boolean
    error?: string | false
}>()
const emit = defineEmits(['update:modelValue'])
const { warning } = useNotification()

const currentValue = ref(props.currency ? '0,00' : '0')
const isInputEditable = ref(false)
const focusTimeout = ref(null)
const incrementInterval = ref(null)
const { isDesktop, isMobile } = useDevice()

const incrementValues = computed(() => {
    if (props.increments && props.increments.length <= 4) { return props.increments }
    return [5, 10, 15, 50]
})

const inputWidth = computed(() => `${currentValue.value.length * 0.75}rem`)

const getDisplayTextFromNumber = (value: number) => {
    const valueDecimalPlaces = String(value).replace(',', '.').split('.')[1]?.length
    const maximumDecimalPlaces = 4
    return Number(
        String(value).replace(',', '.'),
    ).toFixed(
        props.currency
            ? 2
            : props.allowDecimals
                ? valueDecimalPlaces > maximumDecimalPlaces
                    ? maximumDecimalPlaces
                    : valueDecimalPlaces
            ?? 0
                : 0,
    ).replace('.', ',')
}

const getNumberFromDisplayText = (value: string) => Number(String(value).replace(',', '.'))

const increment = (value = 0.5) => {
    const oldValue = getNumberFromDisplayText(currentValue.value)
    const newValue = oldValue + value
    if (props.positiveOnly && newValue <= 0) {
        console.log('warning', oldValue, newValue)
        warning('valor inválido!', 'não pode ser negativo')
    }
    currentValue.value = getDisplayTextFromNumber(
        props.positiveOnly
            ? (newValue <= 0 ? oldValue : newValue)
            : newValue,
    )
    emit('update:modelValue', getNumberFromDisplayText(currentValue.value))
}

const onIncrementMouseDown = (value: number) => {
    let step = 0
    increment(value)
    incrementInterval.value = setInterval(() => {
        increment(value * Math.floor(step / 3))
        step++
    }, 100)
}

const onIncrementMouseUp = () => {
    clearInterval(incrementInterval.value)
    incrementInterval.value = null
}

const onInputClick = () => {
    if (isInputEditable.value === false) {
        isInputEditable.value = true
        focusTimeout.value = setTimeout(() => isInputEditable.value = false, 2000)
    }
}

const onInputFocus = () => {
    if (focusTimeout.value) {
        clearTimeout(focusTimeout.value)
        focusTimeout.value = null
    }
    isInputEditable.value = true
}

const onInputBlur = () => {
    isInputEditable.value = false
}

watchEffect(() => {
    if (props.modelValue && isInputEditable.value === false) {
        currentValue.value = getDisplayTextFromNumber(props.modelValue)
    }
})

watch([currentValue], () => {
    if (currentValue.value.length > 0) {
        emit('update:modelValue', getNumberFromDisplayText(currentValue.value))
    }
}, { immediate: true })

onMounted(() => {
    if (props.modelValue !== null && props.modelValue !== undefined) {
        currentValue.value = getDisplayTextFromNumber(props.modelValue)
    }
})
</script>

<template>
    <div>
        <div flex items-center text-primary my-1>
            <div v-if="labelIcon" :class="labelIcon" text-4 mr-1 />
            <div v-if="label" text-3>
                {{ label }}
            </div>
        </div>
        <div
            flex rounded bg-backgroundlight py-2 px-3 items-center justify-between
            b-1 b-transparent
            :class="[
                error ? '!b-red' : '',
            ]"
        >
            <div
                pill p-1 text-primary
                :class="[
                    error ? '!text-red' : '',
                ]"
                @mousedown="() => isDesktop && onIncrementMouseDown(currency ? -0.5 : -1)"
                @mouseup="() => isDesktop && onIncrementMouseUp()"
                @mouseleave="() => isDesktop && onIncrementMouseUp()"
                @touchstart="() => isMobile && onIncrementMouseDown(currency ? -0.5 : -1)"
                @touchend="() => isMobile && onIncrementMouseUp()"
            >
                <div i-material-symbols-remove />
            </div>
            <div
                text-textcolor-700 flex items-center justify-center flex-auto
                data-test-id="numbered-input-container"
                @click="onInputClick"
            >
                <div v-if="currency" text-textcolor-300 text-14px relative top--1px>
                    R$
                </div>
                <input
                    v-model="currentValue" v-maska="props.currency || props.allowDecimals ? '#*,##' : '#*'"
                    bg-transparent outline-none
                    text-center b-b-2 transition
                    text-textcolor-900 rounded-0
                    :style="{ width: inputWidth }"
                    :disabled="!isInputEditable"
                    :class="[isInputEditable ? 'b-b-primary' : 'b-b-transparent']"
                    step="0.01"
                    @focus="onInputFocus"
                    @blur="onInputBlur"
                >

                <div v-if="unit && !currency" ml-1 text-textcolor-300 text-12px>
                    {{ unit }}
                </div>
            </div>
            <div
                pill p-1 text-primary
                :class="[
                    error ? '!text-red' : '',
                ]"
                @mousedown="() => isDesktop && onIncrementMouseDown(currency ? 0.5 : 1)"
                @mouseup="() => isDesktop && onIncrementMouseUp()"
                @mouseleave="() => isDesktop && onIncrementMouseUp()"
                @touchstart="() => isMobile && onIncrementMouseDown(currency ? 0.5 : 1)"
                @touchend="() => isMobile && onIncrementMouseUp()"
            >
                <div i-material-symbols-add />
            </div>
        </div>
        <div v-if="error" text-red text-12px>
            {{ error }}
        </div>
        <div flex text-textcolor-700 gap-2 mt-2>
            <div
                v-for="incrementValue, i in incrementValues" :key="i" pill grow py-2px px-1 text-center leading-5
                text-14px @click="() => increment(incrementValue)"
            >
                +{{ incrementValue }}
            </div>
        </div>
    </div>
</template>
