type DeviceType = 'desktop' | 'mobile' | 'server'

export const useDevice = () => {
    const getDeviceType = (ua: string): DeviceType => {
        if (process.server) {
            return 'server'
        }
        if (ua.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|ZuneWP7|WVGA|Nokia|Opera Mini|IEMobile|Mobile|Tablet/i)) {
            return 'mobile'
        }
        return 'desktop'
    }

    const device = ref<DeviceType | null>(
        getDeviceType(process.client ? navigator.userAgent : ''),
    )

    const isDesktop = computed(() => device.value === 'desktop')
    const isMobile = computed(() => device.value === 'mobile')
    const isServer = computed(() => device.value === 'server')

    return {
        device,
        isDesktop,
        isMobile,
        isServer,
    }
}
